import { A, useParams } from "@solidjs/router";
import { createSignal, Show } from "solid-js";
import { novaStar } from "~/assets/assets";
import { PhosphorIcon } from "~/widgets/icons";

export default function PlutoGiftDisclaimer() {
  const params = useParams();

  const [showDetails, setShowDetails] = createSignal(false);

  return (
    <div class="flex h-full w-full flex-col overflow-y-auto bg-plutoLoginBgMob bg-cover bg-no-repeat text-white md:w-[800px]">
      <div class="mb-12 p-4">
        <A
          href={`/gifts/${params.giftId}`}
          class="mb-4 flex h-10 w-10 items-center justify-center rounded-full border border-basePrimaryMedium bg-[#010101]"
        >
          <PhosphorIcon
            name="arrow-left"
            fontSize={20}
            size="bold"
            class={`cursor-pointer text-baseTertiaryLight`}
          />
        </A>
        <div>
          <div class="flex flex-col gap-1.5 border-b border-basePrimaryMedium pb-6">
            <div class="flex items-center gap-1">
              <img src={novaStar} alt="" class="h-5 w-5" />
              <p class="text-smallBold uppercase tracking-[1.1px]">The magic</p>
            </div>
            <h1 class="text-[28px] font-semibold">
              Top up gift balance & share happiness
            </h1>
          </div>
          <div class="mb-10 mt-6 flex flex-col gap-8">
            <div class="flex gap-3">
              <PhosphorIcon
                name="star-four"
                fontSize={20}
                size="fill"
                class={`h-[26px] w-[26px] text-baseTertiaryLight`}
              />
              <div class="flex flex-col items-baseline gap-1">
                <h3 class="text-h5">You choose the budget, not us!</h3>
                <p class="text-medium text-baseSecondaryLight">
                  Give a budget to this gift & pay in next step, which becomes
                  the gift balance for the receiver.
                </p>
              </div>
            </div>
            <div class="flex gap-3">
              <PhosphorIcon
                name="star-four"
                fontSize={20}
                size="fill"
                class={`h-[26px] w-[26px] text-baseTertiaryLight`}
              />
              <div class="flex flex-col items-baseline gap-1">
                <h3 class="text-h5">Receiver enjoys full freedom</h3>
                <p class="text-medium text-baseSecondaryLight">
                  The receiver can utilise gift balance across the experiences
                  included in this gift.
                </p>
                <div
                  class="mt-2 flex items-center gap-0.5"
                  onClick={() => setShowDetails(!showDetails())}
                >
                  <PhosphorIcon
                    name={showDetails() ? "minus" : "plus"}
                    fontSize={15}
                    size="thin"
                    class={`h-4 w-4 text-baseTertiaryLight`}
                  />
                  <p class="text-smallBold uppercase tracking-[1.1px] underline">
                    {showDetails() ? "Hide" : "See how"}
                  </p>
                </div>
                <Show when={showDetails()}>
                  <ol class="list-decimal pl-4 text-medium text-baseSecondaryLight">
                    <li>
                      Receiver can use gift balance balance to generate gift
                      cards of the brand in this gift.
                    </li>
                    <li>
                      The gift card can be used to pay for product and services
                      of the brands in this gift.
                    </li>
                  </ol>
                </Show>
              </div>
            </div>
            <div class="flex gap-3">
              <PhosphorIcon
                name="star-four"
                fontSize={20}
                size="fill"
                class={`h-[26px] w-[26px] text-baseTertiaryLight`}
              />
              <div class="flex flex-col items-baseline gap-1">
                <h3 class="text-h5">No extra charges in between</h3>
                <p class="text-medium text-baseSecondaryLight">
                  Entire amount you pay is passed to the receiver as gift
                  balance.
                </p>
              </div>
            </div>
          </div>
          <A
            href={`/gifts/${params.giftId}/budget`}
            class="flex h-[44px] w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
          >
            <p class="text-buttonMedium font-bold text-textDark">
              Awesome! Continue
            </p>
          </A>
        </div>
      </div>
    </div>
  );
}
